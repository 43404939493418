<template>
    <div>
        <div v-if="ShowTreeView">
            <v-btn color="primary" @click="TreeView = false">Grid View</v-btn>
            <v-btn color="primary" @click="TreeView = true">Tree View</v-btn>
            <v-text-field v-model="search"
                          label="Search"
                          single-line
                          clearable
                          hide-details>
                <template v-slot:append>
                    <v-icon>{{searchIcon}}</v-icon>
                </template>
            </v-text-field>
        </div>
        <v-treeview v-if="TreeView"
                    dense
                    hoverable
                    open-on-click
                    item-children="children"
                    :search="search"
                    :items="Tags"
                    item-text="ItemText"
                    item-key="TagId">
            <template slot="label" slot-scope="{ item }">
                <a v-if="IsWordDocument(item) || IsExcelDocument(item) || IsPowerPointDocument(item) || IsPDFDocument(item)" target="_blank" rel="noopener" v-bind:href="$MyLRCUrl.value+'api/Documents/'+item.DocumentId+'.pdf'">
                    <v-icon>{{picture_as_pdf}}</v-icon> {{ item.ItemText}}
                </a>
                <a v-else-if="item.DocumentId && IsMovieDocument(item)" target="_blank" rel="noopener" v-bind:href="$MyLRCUrl.value+'api/Documents/'+item.DocumentId+'.'+GetFileExt(item)">
                    <v-icon>{{movie}}</v-icon> {{ item.ItemText}}
                </a>
                <a v-else-if="item.DocumentId && IsMP3Document(item)" target="_blank" rel="noopener" v-bind:href="$MyLRCUrl.value+'api/Documents/'+item.DocumentId+'.mp3'">
                    <v-icon>{{radio}}</v-icon> {{ item.ItemText}}
                </a>
                <a v-else-if="item.DocumentId" target="_blank" rel="noopener" v-bind:href="$MyLRCUrl.value+'api/Documents/'+item.DocumentId+'.'+GetFileExt(item)">
                    <v-icon>{{download}}</v-icon> {{ item.ItemText}}
                </a>
                <span v-else>{{ item.ItemText}}</span>
            </template>
        </v-treeview>
        <FilterableDataTable v-else
                             :headers="headers"
                             :items="Documents"
                             :search="search"
                             :loading="Loading"
                             :componentName="'Documents'+Title"
                             :editHeaders="true"
                             :hide-default-footer="disablePagination" :disable-pagination="disablePagination"
                             hide-default-header
                             item-key="DocumentId"
                             class="elevation-1">
            <template v-slot:item.DocumentId="props">
                <v-row align="center" no-gutters class="flex-nowrap">
                    <v-col cols="auto">
                        <a target="_blank" rel="noopener" v-bind:href="$MyLRCUrl.value+'/api/Documents/'+props.item.DocumentId">
                            <v-icon>{{picture_as_pdf}}</v-icon>
                        </a>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:item.View="props">
                <v-tooltip bottom v-if="IsWordDocument(props.item) || IsExcelDocument(props.item) || IsPowerPointDocument(props.item) || IsPDFDocument(props.item)">
                    View Document
                    <template v-slot:activator="{ on }">
                        <a target="_blank" v-on="on" rel="noopener" :href="$MyLRCUrl.value+'api/Documents/'+props.item.DocumentId+'.pdf'">
                            <v-btn icon small>
                                <v-icon>{{picture_as_pdf}}</v-icon>
                            </v-btn>
                        </a>
                    </template>
                </v-tooltip>
                <v-tooltip bottom v-else-if="IsExcelDocument(props.item)">
                    View Excel
                    <template v-slot:activator="{ on }">
                        <a target="_blank" v-on="on" rel="noopener" :href="$MyLRCUrl.value+'api/Documents/'+props.item.DocumentId+'.xlsx'">
                            <v-btn icon small>
                                <v-img height="24px" width="24px" src="../Content/images/Microsoft_Office_Excel.svg"></v-img>
                            </v-btn>
                        </a>
                    </template>
                </v-tooltip>
                <v-tooltip bottom v-else-if="IsPowerPointDocument(props.item)">
                    View PowerPoint
                    <template v-slot:activator="{ on }">
                        <a target="_blank" v-on="on" rel="noopener" :href="$MyLRCUrl.value+'api/Documents/'+props.item.DocumentId+'.pptx'">
                            <v-btn icon small>
                                <v-img height="24px" width="24px" src="../Content/images/Microsoft_Office_PowerPoint.svg"></v-img>
                            </v-btn>
                        </a>
                    </template>
                </v-tooltip>
                <v-tooltip bottom v-else-if="IsMovieDocument(props.item)">
                    View Video
                    <template v-slot:activator="{ on }">
                        <a target="_blank" v-on="on" rel="noopener" :href="$MyLRCUrl.value+'api/Documents/'+props.item.DocumentId+'.'+GetFileExt(props.item)">
                            <v-btn icon small>
                                <v-icon>{{movie}}</v-icon>
                            </v-btn>
                        </a>
                    </template>
                </v-tooltip>
                <v-tooltip bottom v-else-if="IsMP3Document(props.item)">
                    Play MP3
                    <template v-slot:activator="{ on }">
                        <a target="_blank" v-on="on" rel="noopener" :href="$MyLRCUrl.value+'api/Documents/'+props.item.DocumentId+'.mp3'">
                            <v-btn icon small>
                                <v-icon>{{radio}}</v-icon>
                            </v-btn>
                        </a>
                    </template>
                </v-tooltip>
                <v-tooltip bottom v-else>
                    Download File
                    <template v-slot:activator="{ on }">
                        <a target="_blank" v-on="on" rel="noopener" :href="$MyLRCUrl.value+'api/Documents/'+props.item.DocumentId+'.'+GetFileExt(props.item)">
                            <v-btn icon small>
                                <v-icon>{{download}}</v-icon>
                            </v-btn>
                        </a>
                    </template>
                </v-tooltip>
            </template>
            <template v-slot:item.Description="props">
                <span class="text-xs-center">
                    {{ props.value | FormatDates }}
                </span>
            </template>
            <template v-slot:item.DocumentDate="props">
                <span class="text-xs-center">
                    {{ props.value | FormatDates }}
                </span>
            </template>
            <template v-slot:item.Created="props">
                <span class="text-xs-center">
                    {{ props.value | FormatDates }}
                </span>
            </template>
            <template v-slot:item.LastUpdated="props">
                <span class="text-xs-center">
                    {{ props.value | FormatDates }}
                </span>
            </template>
            <template v-slot:item.SignedDate="props">
                <span class="text-xs-center">
                    {{ props.value | FormatDates }}
                </span>
            </template>
            <template v-slot:item.EndDate="props">
                <span class="text-xs-center">
                    {{ props.value | FormatDates }}
                </span>
            </template>
            <v-alert slot="no-results" :value="true" type="info">
                Your search for "{{ search }}" found no results.
            </v-alert>
        </FilterableDataTable>
    </div>
</template>
<script>
    import { mdiDownload } from '@mdi/js';
    import { mdiMovie } from '@mdi/js';
    import { mdiFilePdfBox } from '@mdi/js';
    import { mdiRadio } from '@mdi/js';
    import { mdiMagnify } from '@mdi/js';
    const FilterableDataTable = () => import("@/Components/FilterableDataTable.vue");

    export default {
        name: 'Documents',
        props: {
            value: Object,
            Documents: Array,
            TagsProp: Array,
            Title: String,
            InDialog: { type: Boolean, default: false },
            ShowTreeView: { type: Boolean, default: false },
            SessionCommitteeId: { type: Number, default: null },
            InterimYearCommitteeId: { type: Number, default: null },
            ConferenceCommitteeId: { type: Number, default: null },
            LetterOfIntentHeaders: { type: Boolean, default: false },
            disablePagination: { type: Boolean, default: false },
        },
        components: {
            FilterableDataTable
        },
        methods: {
            IsWordDocument(item) {
                if (item.Filename && item.Filename.toLowerCase().endsWith('.docx')) {
                    return true;
                }
                return false;
            },
            IsExcelDocument(item) {
                if (item.Filename && item.Filename.toLowerCase().endsWith('.xlsx')) {
                    return true;
                }
                return false;
            },
            IsPowerPointDocument(item) {
                if (item.Filename && item.Filename.toLowerCase().endsWith('.pptx')) {
                    return true;
                }
                return false;
            },
            IsPDFDocument(item) {
                if (item.Filename && this.GetFileExt(item).endsWith('pdf')) {
                    return true;
                }
                else if (item.Webfilename && item.Webfilename.toLowerCase().endsWith('.pdf')) {
                    return true;
                }
                return false;
            },
            IsMovieDocument(item) {
                if (!item.Filename)
                    return false;
                let LowerFileName = item.Filename.toLowerCase();
                if (LowerFileName.endsWith('.mp4')) {
                    return true;
                }
                else if (LowerFileName.endsWith('.avi')) {
                    return true;
                }
                else if (LowerFileName.endsWith('.mov')) {
                    return true;
                }
                else if (LowerFileName.endsWith('.wma')) {
                    return true;
                }
                else if (LowerFileName.endsWith('.3gp')) {
                    return true;
                }
                return false;
            },
            IsMP3Document(item) {
                if (item.Filename && item.Filename.toLowerCase().endsWith('.mp3')) {
                    return true;
                }
                return false;
            },
            GetFileExt(item) {
                if (item.Filename && item.Filename.indexOf('.') >= 0) {
                    return item.Filename.split('.').pop().toLowerCase();
                }
                return 'pdf';
            },
            MemberTypeAbbreviation: function (value) {
                return MemberTypeAbbreviation(value);
            },
            MemberType: function (value) {
                return MemberType(value);
            },
            CommitteeBody: function (value) {
                return CommitteeBody(value);
            },
            LoadTags() {
                if (this.ShowTreeView) {
                    myfetch('/api/Tags').then(r => {
                        if (r.ok) {
                            r.json().then(r => {
                                let tags = r;
                                let unsorted = { TagId: -1, ItemText: 'Unsorted', children: [], Description: 'Unsorted' };

                                tags.push(unsorted);

                                tags.forEach(x => {
                                    x.ItemText = x.Description;
                                    x.children = [];
                                });

                                tags.sort((x, y) => {
                                    if (x.ItemText > y.ItemText)
                                        return 1;
                                    else if (x.ItemText < y.ItemText)
                                        return -1;
                                    else
                                        return 0;
                                });
                                if (this.Documents) {
                                    this.Documents.forEach(x => {
                                        let found = false;

                                        x.ItemText = x.Title;

                                        if (!x.ItemText)
                                            x.ItemText = x.Description;

                                        if (!x.ItemText)
                                            x.ItemText = x.Filename;

                                        x.TagId = x.DocumentId;

                                        (x.Tags || []).forEach(y => {
                                            let matchedTag = tags.find(z => z.Description == y);
                                            if (matchedTag) {
                                                matchedTag.children.push(x);
                                                found = true;
                                            }
                                        });

                                        if (!found) {
                                            unsorted.children.push(x);
                                        }
                                    });
                                }
                                let children = tags.filter(x => x.children.length > 0 && x.ParentTagId);
                                children.forEach(x => {
                                    let parent = tags.find(y => y.TagId == x.ParentTagId);
                                    if (parent) {
                                        parent.children.push(x);
                                        tags.splice(tags.indexOf(x), 1);
                                    }
                                });

                                tags = tags.filter(x => x.children.length > 0);

                                this.Tags = tags;
                            }).catch((e) => {
                                console.error(e);
                            });
                        }
                    });
                }

            },
        },
        data: function () {
            return {
                download: mdiDownload,
                movie: mdiMovie,
                radio: mdiRadio,
                picture_as_pdf: mdiFilePdfBox,
                searchIcon: mdiMagnify,
                Loading: false,
                sortStack: [],
                headers: [
                    {
                        text: 'View',
                        value: 'View',
                        width: '1px',
                        visible: true,
                        filterable: false,
                    },
                    {
                        text: 'Date', value: 'DocumentDate', width: '1px',
                        visible: this.LetterOfIntentHeaders
                    },
                    {
                        text: 'Type',
                        value: 'DocumentType',
                        width: '30ch',
                        visible: this.LetterOfIntentHeaders
                    },
                    {
                        text: 'Title',
                        value: 'Title',
                        width: '*',
                        visible: true
                    },
                    {
                        text: 'Description',
                        value: 'Description',
                        width: '200px',
                        visible: !this.LetterOfIntentHeaders
                    },
                    {
                        text: 'Agency', value: 'AgencyName', width: '*',
                        visible: true
                    },
                    {
                        text: 'Session Committee', value: 'SessionCommitteeName', width: '1px',
                        visible: false
                    },
                    {
                        text: 'Interim Committee', value: 'InterimCommitteeName', width: '1px',
                        visible: false
                    },
                    {
                        text: 'Conference Committee', value: 'ConferenceCommitteeName', width: '1px',
                        visible: false
                    },
                    {
                        text: 'Bill', value: 'BillNumber', width: '1px',
                        visible: false
                    },
                    {
                        text: 'Version', value: 'BillVersionDescription', width: '1px',
                        visible: false
                    },
                    {
                        text: 'BillId', value: 'BillId', width: '1px',
                        visible: false
                    },
                    {
                        text: 'Room', value: 'Room', width: '1px',
                        visible: false
                    },
                    {
                        text: 'Signed', value: 'SignedDate', width: '1px',
                        visible: false
                    },
                    {
                        text: 'Last Updated', value: 'LastUpdated', width: '1px',
                        visible: false
                    },
                    {
                        text: 'Session', value: 'SessionName', width: '1px',
                        visible: false
                    },
                    {
                        text: 'Tags', value: 'Tags', width: '200px',
                        visible: !this.LetterOfIntentHeaders
                    },

                ],
                search: null,
                TreeView: false,
                Tags: [],
            }
        },
        watch: {
            Documents: function () {
                this.LoadTags();
            }
        },
        mounted() {
            if (this.Documents && this.Documents.length > 0) {
                this.LoadTags();
            }
            let urlParams = new URLSearchParams(window.location.search);
            if (urlParams) {
                let TreeViewParam = urlParams.get('TreeView');
                if (TreeViewParam) {
                    this.TreeView = JSON.parse(TreeViewParam);
                }
                else {
                    TreeViewParam = urlParams.get('treeview');
                    if (TreeViewParam) {
                        this.TreeView = JSON.parse(TreeViewParam);
                    }
                }
            }
        },
        computed: {
        },
        created() {
        }
    };
</script>
<style></style>